/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import {
  Grid,
  TextInput,
  Textarea,
  Button,
  FileInput,
  Checkbox,
  Loader,
  Group,
  Text,
  SelectItemProps,
  Autocomplete,
  createStyles,
  Select,
  Accordion,
  MantineColor,
  ActionIcon,
} from '@mantine/core'

import { IconUpload, IconCheck, IconX, IconPlus } from '@tabler/icons'
import { filter, get, map } from 'lodash'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import ReactQuill from 'react-quill'
import Compressor from 'compressorjs'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { AlertColor, AlertIcons, API_ENDPOINTS } from '../../../constants'
import EditorToolbar, { modules, formats } from '../../../components/TextEditor'
import { API_SEVICES } from '../../../config/services'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { openSnackbar } from '../../../redux/snackbar'
import SpinningLoader from '../../../components/SpinningLoader'

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
    fontStyle: 'Helvetica',
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
  formikError: {
    color: '#CC0000',
    fontSize: '13px',
  },
  outline: {
    border: 'none',
    outline: 'none !important',
  },
}))
interface IProps {
  fetchData: () => void
  onClose: () => void
  mode: string | null
  isEditMode: boolean
  isViewMode: boolean
  productId?: string | null
}
interface ItemProps extends SelectItemProps {
  _id: any
  name: string
  description: string
  cityName: string
  store: any
}
interface ItemProps extends SelectItemProps {
  color: MantineColor
  description: string
  image: string
}

function ArticleModelForm(props: IProps) {
  const dispatch = useAppDispatch()
  const { onClose, mode, isEditMode, isViewMode, fetchData, productId } = props
  const [images, setImages] = useState<any | null>(null)

  const [brandData, setBrandData] = useState<any>([])
  const { classes } = useStyles()
  const [textValue, setTextValue] = useState('')
  const [imageLoading, setImageLoading] = useState(false)
  const [brandIds, setBrandIds] = useState('')
  const id = productId
  const [loading, setLoading] = useState(false)
  const timeoutRef = useRef<number>(-1)
  const [dataProduct, setDataProduct] = useState<string[]>([])
  const [loading1, setLoading1] = useState(false)
  const [articleImage, setArticleImage] = useState<any | null>(null)
  const [featuresList, setFeaturesList] = useState<any[]>([''])
  const [category, setCategory] = useState([])
  const navigate = useNavigate()

  const featuresRemoveField = (index: any) => {
    const list = [...featuresList]
    list.splice(index, 1)
    setFeaturesList(list)
  }
  const featuresInputChange = (e: any, index: any) => {
    const { value } = e.target
    const list: any = [...featuresList]
    list[index] = value
    setFeaturesList(list)
  }
  const featuresAddClick = () => {
    setFeaturesList([...featuresList, ''])
  }
  const HandleChange = (val: string) => {
    window.clearTimeout(timeoutRef.current)
  }
  useEffect(() => {
    const match = {
      status: 'true',
    }
    const mat = JSON.stringify(match)
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${mat}`,
      (res: AxiosResponse) => {
        console.log("response",res)
        setBrandData(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Brand Details Cant be fetched'
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, name, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  SelectItem.displayName = 'SelectItem'

  useEffect(() => {
    if (brandIds !== '') {
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?id=${brandIds}`,
        (res: AxiosResponse) => {
          setDataProduct(res.data.data.data)
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'product Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    }
  }, [brandIds])


  const AutocompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, store, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{label}</Text>-<Text>{store}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutocompleteItem.displayName = 'AutocompleteItem'

  const formik = useFormik({
    initialValues: {
      brandId: '',
      title: '',
     
      description: '',
      storeId:"",
    
      productImage: '',
  
      status: true,
    },
    onSubmit: (values) => {

      const formData = new FormData()
      formData.append('brandId', values.brandId)
      formData.append('title', values.title)
      formData.append('storeId', values.storeId)
      formData.append('description', values.description)
      formData.append('status', JSON.stringify(values.status))
      if (values.productImage !== '') {
        formData.append('file', values.productImage)
      }
      setLoading1(true)
      !isEditMode || isViewMode
        ? API_SEVICES.PostRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.POST_BLOGS}`,
            (res: AxiosResponse) => {
              setLoading1(false)

              dispatch(
                openSnackbar({
                  messege: 'Article Added Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (
                get(err, 'response.status', '') === 401 ||
                get(err, 'response.status', '') === 403
              ) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Article cant be  Added'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            formData
          )
        : API_SEVICES.PatchRequest(
            `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.UPDATE_BLOG}?id=${id}`,
            (res: AxiosResponse) => {
              setLoading1(false)
              dispatch(
                openSnackbar({
                  messege: 'Blogs Updated Sucessfully',
                  icon: AlertIcons.success,
                  title: 'Success',
                  color: AlertColor.success,
                })
              )
              onClose()
              fetchData()
            },
            (err: AxiosError) => {
              setLoading1(false)
              if (get(err, 'response.status') === 403) {
                localStorage.clear()
                navigate('/login', { replace: true })
              }
              dispatch(
                openSnackbar({
                  messege: get(
                    err,
                    'response.data.message',
                    'Blogs cant be  Updated'
                  ),
                  icon: AlertIcons.error,
                  title: 'error',
                  color: AlertColor.error,
                })
              )
              console.error(err)
            },
            formData
          )
   
    },
    
  })

  useEffect(() => {
    if (id) {
      setLoading(true)
 
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${
          API_ENDPOINTS.FIND_ONE_BLOG
        }?id=${id}`,

        (res: AxiosResponse) => {
          setLoading(false)
          setBrandIds(res?.data?.data?.data[0].brandId._id)

          setTextValue(get(res, 'data.data.data[0].description', ''))
          setImages(res.data.data.data[0].productImage)

          formik.setFieldValue(
            'brandId',
            get(res, 'data.data.data[0].brandId._id', '')
          )
          formik.setFieldValue(
            'description',
            get(res, 'data.data.data[0].description', '')
          )
  formik.setFieldValue(
            'status',
            get(res, 'data.data.data[0].status', false)
  )
  formik.setFieldValue(
    'storeId',
    get(res, 'data.data.data[0].storeId', "")
  )
         

          formik.setFieldValue('title', get(res, 'data.data.data[0].title', ''))

          formik.setFieldValue(
            'productImage',
            get(res, 'data.data.data[0].productImage.file', '')
          )

         
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const controller = new AbortController()
    API_SEVICES.GetRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.CATEGORY_DETAILS}`,
      (res: AxiosResponse) => {
        setLoading(false)

        setCategory(res.data.data)
      },
      (err: AxiosError) => {
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        if (err.message !== 'canceled') {
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Category Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
        }
        console.error(err)
      },
      controller.signal
    )
  }, [])


  const imageChange = async (e: any) => {
    if (
      e.type === 'image/png' ||
      e.type === 'image/jpeg' ||
      e.type === 'image/webp' ||
      e.type === 'image/jpg'
    ) {
      const file = e
      const image = new Compressor(file, {
        quality: 0.7,
        success: (compressedResult: any) => compressedResult,
      })
      const image1 = Object.values(image)
      setArticleImage(image1[0])
      formik.setFieldValue('productImage', image1[0])
    } else {
      setArticleImage(null)
      dispatch(
        openSnackbar({
          messege: 'Image File Format is not supported',
          icon: AlertIcons.error,
          title: 'error',
          color: AlertColor.error,
        })
      )
    }
  }
  const removeImage = () => {
    setArticleImage(null)
    setImages(null)
    formik.setFieldValue('productImage', '')
  }
  const handleChangeEditor = (e: any) => {
    formik.setFieldValue('description', e)
    setTextValue(e)
  }
  // eslint-disable-next-line react/display-name
  const handleRemoveGal = (idx: any) => {
    setImageLoading(true)
    API_SEVICES.DeleteRequest(
      `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.DELETE_ARTICLEIMAGE}?id=${idx._id}`,
      (res: AxiosResponse) => {
        setImageLoading(false)

        setArticleImage(null)
        setImages(null)
        formik.setFieldValue('productImage', '')
        dispatch(
          openSnackbar({
            messege: 'Image Deleted Sucessfully',
            icon: AlertIcons.success,
            title: 'Success',
            color: AlertColor.success,
          })
        )

        // onClose()
      },
      (err: AxiosError) => {
        setLoading1(false)
        setImageLoading(false)
        if (
          get(err, 'response.status', '') === 401 ||
          get(err, 'response.status', '') === 403
        ) {
          localStorage.clear()
          navigate('/login', { replace: true })
        }
        dispatch(
          openSnackbar({
            messege: get(
              err,
              'response.data.message',
              'Product image cant be Deleted '
            ),
            icon: AlertIcons.error,
            title: 'error',
            color: AlertColor.error,
          })
        )
        console.error(err)
      }
    )
  }

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, value, label, image, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{label}</Text>
          </div>
        </Group>
      </div>
    )
  )
  AutoCompleteItem.displayName = 'AutoCompleteItem'

  const brand = useMemo(
    () =>
      brandData.map((item: any) => ({
        ...item,
        label: item.name,
        value: item._id,
      })),
    [brandData]
  )

  return (
    <div className={classes.outline} role="button" tabIndex={0}>
      <Accordion defaultValue="ArticlesDetails" sx={{ minHeight: '200px' }}>
        {loading ? (
          <SpinningLoader />
        ) : (
          <>
            {isEditMode ? (
              <Text fz="sm" style={{ fontSize: '15px' }}>
                Article Title : {formik.values.title}
              </Text>
            ) : null}
            <Accordion.Item value="ArticlesDetails">
              <Accordion.Control>Articles Details</Accordion.Control>
              <Accordion.Panel>
                <Grid>
                  <Grid.Col xs={12}>
                    <Select
                      label="Select Brand"
                      placeholder="e.g Select Brand"
                      itemComponent={SelectItem}
                      data={brand}
                      limit={brand.length}
                      withAsterisk
                      maxDropdownHeight={200}
                      value={brandIds}
                      nothingFound="Nobody here"
                      onChange={(value: any) => {
                        setBrandIds(value)
                        formik.setFieldValue('productName', '')
                        formik.setFieldValue('brandId', value)
                      }}
                      styles={(theme) => ({
                        item: {
                          // applies styles to selected item
                          '&[data-selected]': {
                            '&, &:hover': {
                              backgroundColor:
                                theme.colorScheme === 'dark'
                                  ? theme.colors.teal[9]
                                  : theme.colors.teal[1],
                              color:
                                theme.colorScheme === 'dark'
                                  ? theme.white
                                  : theme.colors.teal[9],
                            },
                          },

                          // applies styles to hovered item (with mouse or keyboard)
                          '&[data-hovered]': {},
                        },
                      })}
                    />

                    {formik.errors.brandId && formik.touched.brandId && (
                      <div className={classes.formikError}>
                        {formik.errors.brandId}
                      </div>
                    )}
                  </Grid.Col>

                 
               
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g Article Title"
                      name="title"
                      label="Title"
                      withAsterisk
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className={classes.formikError}>
                        {formik.errors.title}
                      </div>
                    )}
                    </Grid.Col>
                          
                  <Grid.Col xs={12}>
                    <TextInput
                      placeholder="e.g StoreId"
                      name="storeId"
                      label="StoreId"
                      withAsterisk
                      onChange={formik.handleChange}
                      value={formik.values.storeId}
                    />
                    {formik.errors.storeId && formik.touched.storeId && (
                      <div className={classes.formikError}>
                        {formik.errors.storeId}
                      </div>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <div style={{ minHeight: '400px' }}>
                      <div>
                        <Text
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#212529',
                          }}
                        >
                          {' '}
                          Description
                        </Text>
                        <EditorToolbar />
                        <ReactQuill
                          style={{ height: '350px' }}
                          theme="snow"
                          value={textValue}
                          modules={modules}
                          formats={formats}
                          onChange={(e) => handleChangeEditor(e)}
                        />
                      </div>
                    </div>
                    {formik.errors.description &&
                      formik.touched.description && (
                        <div className={classes.formikError}>
                          {formik.errors.description}
                        </div>
                      )}
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <FileInput
                      label="Article Images (Only image/png,image/jpeg,image/webp file format is supported)"
                      // placeholder="image/png,image/jpeg,image/webp,image.jpg"
                      description="image/png,image/jpeg,image/webp,image.jpg"
                      icon={<IconUpload size={14} />}
                      name="productImage"
                      // clearable
                      accept="image/png,image/jpeg,image/webP"
                      onChange={imageChange}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    {articleImage ? (
                      <Grid>
                        <Grid.Col xs={6}>
                          {articleImage && (
                            <div>
                              <img
                                src={URL.createObjectURL(articleImage)}
                                height={180}
                                width={300}
                                alt="logoFile"
                                style={{ marginTop: 10 }}
                              />
                            </div>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={4}>
                          <Button
                            onClick={() => removeImage()}
                            style={{ background: 'none' }}
                          >
                            <ActionIcon
                              sx={{
                                color: 'black',
                                backgroundImage:
                                  'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                              }}
                            >
                              <IconX size={15} />
                            </ActionIcon>
                          </Button>
                        </Grid.Col>
                      </Grid>
                    ) : (
                      <div>
                        {images?.file && (
                          <Grid>
                            <Grid.Col xs={6}>
                              <img
                                src={images?.file?.Location}
                                height={180}
                                width={300}
                                alt="logoFile"
                                style={{ margin: 10 }}
                              />
                            </Grid.Col>
                            <Grid.Col xs={4}>
                              <Button
                                style={{
                                  background: 'none',
                                }}
                                onClick={() => {
                                  handleRemoveGal(images)
                                }}
                              >
                                {!imageLoading ? (
                                  <ActionIcon
                                    sx={{
                                      color: 'black',
                                      backgroundImage:
                                        'linear-gradient(45deg, #fd7e14 0%, #fa5252 100%)',
                                    }}
                                  >
                                    <IconX size={15} />
                                  </ActionIcon>
                                ) : (
                                  <Loader color="green" size="xs" />
                                )}
                              </Button>
                            </Grid.Col>
                          </Grid>
                        )}
                      </div>
                    )}
                  </Grid.Col>
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>

          
          </>
        )}
      </Accordion>
      <Grid style={{ marginTop: '10px' }}>
        <Grid.Col xs={8}>
          <Checkbox
            checked={formik.values.status}
            required
            name="status"
            label={formik.values.status === true ? 'Active' : 'Inactive'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid.Col>

        <Grid.Col xs={2}>
          <Button
            variant="gradient"
            onClick={() => {
              onClose()
            }}
            gradient={{ from: 'orange', to: 'red' }}
            disabled={loading1}
          >
            Cancel
          </Button>
        </Grid.Col>
        <Grid.Col xs={2}>
          <Button
            variant="gradient"
            gradient={{ from: 'teal', to: 'lime', deg: 105 }}
            className={!(formik.dirty && formik.isValid) ? 'disabled-btn' : ''}
            disabled={!isEditMode ? !(formik.dirty && formik.isValid) : false}
            onClick={() => {
              formik.handleSubmit()
              if (formik.isValid === false) {
                dispatch(
                  openSnackbar({
                    messege: 'Enter all required the fields!!',
                    icon: AlertIcons.error,
                    title: 'Error',
                    color: AlertColor.error,
                  })
                )
              }
            }}
            rightIcon={loading1 ? <Loader size={14} color="white" /> : ''}
          >
            {isEditMode ? 'Update' : 'Save'}
          </Button>
        </Grid.Col>
      </Grid>
    </div>
  )
}
export default ArticleModelForm
