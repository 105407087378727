import { Navigate, useNavigate, useRoutes } from 'react-router-dom'
import {
  AppShell,
  Burger,
  createStyles,
  Grid,
  Group,
  Header,
  MediaQuery,
  Navbar,
  useMantineTheme,
} from '@mantine/core'
import { useState } from 'react'
import { IconLogout } from '@tabler/icons'
import { ROUTE_PATH } from '../constants'
import ViewTypesData from '../pages/Types'
import ViewCategoryData from '../pages/Category'
import ViewBrandForm from '../pages/Brand'
import Store from '../pages/Store'
import Product from '../pages/Product'
import State from '../pages/State'
import City from '../pages/City'
import SubCategory from '../pages/SubCategory'
import Login from '../pages/login'
import PageNotFound from '../pages/404'
import { drawerOptions } from './drawer'
import { NavbarCustom } from '../components/NavbarCustom'
import Users from '../pages/User'
import KnowlarityMapping from '../pages/knowlarityMapping'
import Calllog from '../pages/Calllog'
import CallActions from '../pages/ActionsPage'
import Discovery from '../pages/Discovery'
import logo from '../assets/Closebi.png'
import UserButton from '../components/UserButton'
import Keywords from '../pages/Keywords'
import MonthlyReport from '../pages/MonthlyReport'
import TextFile from '../pages/Indexing'
import { getLocalStorageItem } from '../utils/localStorage'
import Articles from '../pages/Articles'
import PagesonFly from '../pages/PagesOnFly'
import URLIndex from '../pages/URLViewing'
import KeywordList from '../pages/keywordsList'
import SeoRankingTool from '../pages/SeoRankingTool'
import Article from '../pages/Blog'

const useStyles = createStyles((theme) => ({
  header: {
    padding: theme.spacing.md,
    marginLeft: -theme.spacing.sm,
    marginRight: -theme.spacing.sm,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  logo: {
    marginLeft: '30px',
    '@media (max-width: 912px)': {
      marginLeft: '-20px',
    },
  },
}))

function AdditionalLayout(children: JSX.Element) {
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const userName = JSON.parse(getLocalStorageItem('user') as any)
  const role = getLocalStorageItem('role') as any
  return (
    <AppShell
      navbarOffsetBreakpoint="md"
      fixed
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="md"
          hidden={!opened}
          width={{ base: 210 }}
          style={{ zIndex: 2 }}
        >
          <NavbarCustom options={drawerOptions} />
        </Navbar>
      }
      header={
        <Header height={70} p="md" style={{ zIndex: 2 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              height: '100%',
            }}
          >
            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div className={classes.header}>
              <Group position="apart" className={classes.logo}>
                <img src={logo} height={45} width={100} alt="logo" />
              </Group>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              marginTop: '-50px',
            }}
          >
            <Grid>
              <Grid.Col md={12} lg={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <UserButton
                    image="Avatar radius=xl"
                    name={userName}
                    email={role}
                  />
                  <IconLogout
                    size={20}
                    onClick={() => {
                      localStorage.clear()
                      navigate('/login', { replace: true })
                    }}
                  />
                </div>
              </Grid.Col>
            </Grid>
          </div>
        </Header>
      }
      styles={() => ({
        main: {
          overflowX: 'hidden',
        },
      })}
    >
      {children}
    </AppShell>
  )
}
function Layout() {
  const router = useRoutes([
    {
      path: ROUTE_PATH.seotool,
      element: AdditionalLayout(<SeoRankingTool />),
    },
    {
      path: ROUTE_PATH.brand,
      element: AdditionalLayout(<ViewBrandForm />),
    },
    {
      path: ROUTE_PATH.category,
      element: AdditionalLayout(<ViewCategoryData />),
    },
    {
      path: ROUTE_PATH.subCategory,
      element: AdditionalLayout(<SubCategory />),
    },
    {
      path: ROUTE_PATH.type,
      element: AdditionalLayout(<ViewTypesData />),
    },
    {
      path: ROUTE_PATH.store,
      element: AdditionalLayout(<Store />),
    },
    {
      path: ROUTE_PATH.product,
      element: AdditionalLayout(<Product />),
    },
    {
      path: ROUTE_PATH.city,
      element: AdditionalLayout(<City />),
    },
    {
      path: ROUTE_PATH.state,
      element: AdditionalLayout(<State />),
    },
    {
      path: ROUTE_PATH.knowlarity,
      element: AdditionalLayout(<KnowlarityMapping />),
    },
    {
      path: ROUTE_PATH.user,
      element: AdditionalLayout(<Users />),
    },
    {
      path: ROUTE_PATH.calllog,
      element: AdditionalLayout(<Calllog />),
    },

    {
      path: ROUTE_PATH.keywords,
      element: AdditionalLayout(<Keywords />),
    },

    {
      path: ROUTE_PATH.callActions,
      element: AdditionalLayout(<CallActions />),
    },
    {
      path: ROUTE_PATH.discovery,
      element: AdditionalLayout(<Discovery />),
    },
    {
      path: ROUTE_PATH.articles,
      element: AdditionalLayout(<Articles />),
    },
    {
      path: ROUTE_PATH.fileUpload,
      element: AdditionalLayout(<TextFile />),
    },
    {
      path: ROUTE_PATH.monthlyReport,
      element: AdditionalLayout(<MonthlyReport />),
    },
    {
      path: ROUTE_PATH.pagesonfly,
      element: AdditionalLayout(<PagesonFly />),
    },
    {
      path: ROUTE_PATH.keywordList,
      element: AdditionalLayout(<KeywordList />),
    },
    {
      path: ROUTE_PATH.URLIndex,
      element: AdditionalLayout(<URLIndex />),
    },
    {
      path: ROUTE_PATH.blogArticles,
      element: AdditionalLayout(<Article />),
    },
    {
      path: ROUTE_PATH.login,
      element: <Login />,
    },
    {
      path: ROUTE_PATH.home,
      element: <Navigate to={ROUTE_PATH.login} replace={true} />,
      children: [],
    },

    {
      path: '*',
      element: <PageNotFound />,
      children: [],
    },
  ])
  return router
}
export default Layout
