/* eslint-disable max-len */
import {
    Box,
    Paper,
    Title,
    Grid,
    Button,
    Modal,
    Text,
    Group,
    Select,
    SelectItemProps,
    createStyles,
    Menu,
    ThemeIcon,
    Popover,
  } from '@mantine/core'
  import {
    IconUpload,
    IconEyeCheck,
    IconEdit,
    IconTrash,
    IconDotsVertical,
    IconCircleCheck,
    IconCircleX,
    IconBuildingSkyscraper,
    IconFilter,
    IconRefresh,
  } from '@tabler/icons'
  import { useNavigate, useSearchParams } from 'react-router-dom'
  import axios, { AxiosError, AxiosResponse } from 'axios'
  import _, { get } from 'lodash'
  import { DataTableColumn } from 'mantine-datatable'
  import { useEffect, useMemo, useState, forwardRef, useRef, useCallback } from 'react'
  import CustomTable from '../../components/NewcustomTable'
  import { API_SEVICES } from '../../config/services'
  import imagenotfound from '../../assets/imagenotfound.webp'
  import {
    API_ENDPOINTS,
    AlertColor,
    AlertIcons,
    PAGESIZES,
    ROLEPROVIDED,
  } from '../../constants'
  import { openSnackbar } from '../../redux/snackbar'
  import { useAppDispatch, useAppSelector } from '../../redux/store'
  import DeleteModal from '../../components/DeleteModal'
  import ArticleUpload from './ArticleModelForm'
  import ArticleViewForm from './ArticlesViewForm'
  
  interface ImageProps {
    Location: string
  }
  interface ItemProps extends SelectItemProps, ImageProps {
    _id: any
    name: string
    description: string
    cityName: string
    logo: ImageProps
    storeName: string
  }
  const useStyles = createStyles((theme) => ({
    wrapper: {
      position: 'relative',
      marginBottom: 30,
      fontStyle: 'Helvetica',
    },
  
    dropzone: {
      borderWidth: 1,
      paddingBottom: 50,
    },
  
    icon: {
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[3]
          : theme.colors.gray[4],
    },
  
    control: {
      position: 'absolute',
      width: 250,
      left: 'calc(50% - 125px)',
      bottom: -20,
    },
    filter: {
      marginLeft: '102px',
      '@media (max-width: 912px)': {
        marginLeft: '90px',
      },
    },
    import: {
      marginLeft: '38px',
      '@media (max-width: 912px)': {
        marginLeft: '20px',
      },
    },
    csv: {
      marginLeft: '15px',
      '@media (max-width: 912px)': {
        marginLeft: '10px',
      },
    },
    product: {
      marginLeft: '25px',
      '@media (max-width: 912px)': {
        marginLeft: '30px',
      },
    },
  }))
  export default function Article() {
    const [searchParams, setSearchParams] = useSearchParams()
    const mode = searchParams.get('mode')
    const [modelState, setModel] = useState(false)
    const [brandData, setBrandData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [countFrom, setCountForm] = useState<any>()
    const [countTo, setCountTo] = useState<any>()
    const [recordData, setRecordData] = useState([])
    const [page, setPage] = useState(1)
    const [pageNo, setPageNo] = useState(1)
    const [pageSize, setPageSize] = useState(PAGESIZES[0])
    const [totalRowSize, setTotalRowSize]: any = useState(10)
    const { theme } = useStyles()
    const [open, setOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [brandValue, setBrandValue] = useState<any>(null)
    const [storeValue, setStoreValue] = useState<any>(null)
    const [tableHeight, setTableHeight] = useState(500)
    const deleteProduct = API_ENDPOINTS.DELETE_BLOG
    const deletedData = API_ENDPOINTS.GET_BLOGS
    const deleteName = 'Blog'
    const dispatch = useAppDispatch()
    const filterRef = useRef<HTMLInputElement>(null)
    const role:any = useAppSelector((state) => state.role)
    const navigate = useNavigate()
    const roleProvided = role.role || ROLEPROVIDED
  
    const handleClose = () => {
      setModel(false)
      setDeleteOpen(false)
    }

    const fetchData = useCallback(() => {
      setLoading(true)
      const mat = JSON.stringify({pageNo:page,size:pageSize})
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BLOGS}?match=${mat}`,
        (res: AxiosResponse) => {
          setLoading(false)
  
          setTotalRowSize(res?.data?.data?.TotalRecords || 0)
          setRecordData(res?.data?.data?.data || [])
        },
        (err: AxiosError) => {
          setLoading(false)
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Blogs Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
    },[dispatch,navigate,page,pageSize])
  
  
    useEffect(() => {
      setLoading(true)
  
      const controller = new AbortController()
      const match = {
        brandId: brandValue,
      }
      const mat = JSON.stringify({pageNo:page,size:pageSize})
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BLOGS}?match=${mat} `,
        (res: AxiosResponse) => {
         
          setLoading(false)
          if (res?.data?.data?.data?.length > 0) {
            setTotalRowSize(res?.data?.data?.TotalRecords)
            setRecordData(res?.data?.data?.data)
          } else {
            setTotalRowSize(0)
            setRecordData([])
            dispatch(
              openSnackbar({
                messege: 'Blogs Details Not found',
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          } else if (err.message !== 'canceled') {
            dispatch(
              openSnackbar({
                messege: get(
                  err,
                  'response.data.message',
                  'Blogs Details Cant be fetched'
                ),
                icon: AlertIcons.error,
                title: 'error',
                color: AlertColor.error,
              })
            )
          }
          console.error(err)
        },
        controller.signal
      )
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, brandValue, storeValue])
    useEffect(() => {
      const match = {
        status: 'true',
      }
      const mat = JSON.stringify(match)
      API_SEVICES.GetRequest(
        `${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.GET_BRAND}?match=${mat}`,
        (res: AxiosResponse) => {
          setBrandData(res.data.data)
        },
        (err: AxiosError) => {
          if (
            get(err, 'response.status', '') === 401 ||
            get(err, 'response.status', '') === 403
          ) {
            localStorage.clear()
            navigate('/login', { replace: true })
          }
          dispatch(
            openSnackbar({
              messege: get(
                err,
                'response.data.message',
                'Brand Details Cant be fetched'
              ),
              icon: AlertIcons.error,
              title: 'error',
              color: AlertColor.error,
            })
          )
          console.error(err)
        }
      )
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

const handleDeleteBlogs=useCallback(async(rowData:any)=>{

  const match = JSON.stringify({
    productId: rowData?._id,
    imageId: rowData?.Image[0]?._id,
    file:rowData?.Image[0]?.file
  })
  await axios.delete(`${process.env.REACT_APP_BACKEND_API_PROXY}${API_ENDPOINTS.DELETE_BLOG}?match=${match}`).then((res: any) => {
    fetchData()
     dispatch(
       openSnackbar({
         messege: 'Blog Deleted Sucessfully',
         icon: AlertIcons.success,
         title: 'Success',
         color: AlertColor.success,
       })
     )
    
  }).catch((err: any) => {
    fetchData()
    dispatch(
      openSnackbar({
        messege: 'Blog Not Deleted',
        icon: AlertIcons.error,
        title: 'error',
        color: AlertColor.error,
      })
    )
   })
},[dispatch,fetchData])

  
  
    const brand = brandData.map((item: any) => ({
      ...item,
      value: item._id,
      label: item.name,
    }))
  
    const colDef: DataTableColumn<any>[] = useMemo(
      () => [
        {
          Header: 'Actions',
          accessor: 'actions',
          fixed: 'left',
          title: <Text mr="xs">Actions</Text>,
          textAlignment: 'left',
          // width: 10,
          maxWidth: 10,
          Cell: ({ ...row }) => (
            <Menu shadow="md" width={130} position="right-start">
              <Menu.Target>
                <Button style={{ background: 'none' }}>
                  {' '}
                  <IconDotsVertical size={16} color="black" /> 
                </Button>
              </Menu.Target>
  
              <Menu.Dropdown>
                <Menu.Item
                  color="green"
                  icon={<IconEyeCheck size={14} />}
                  onClick={() => {
                    setOpen(true)
                    setSearchParams({
                      mode: 'view',
                      id: row?.row?.original._id,
                    })
                  }}
                >
                  View
                </Menu.Item>
                <Menu.Item
                  color="blue"
                  onClick={() => {
                    setModel(true)
                    setSearchParams({
                      mode: 'edit',
                      id: row?.row?.original._id,
                    })
                  }}
                  icon={<IconEdit size={14} />}
                  disabled={
                    roleProvided === 'user' || roleProvided === 'brand manager'
                  }
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  color="red"
                  onClick={() => {
                    setSearchParams({
                      mode: 'delete',
                      id: row?.row?.original._id,
                    })
                                          handleDeleteBlogs(row?.row?.original)

                                        }}
                  icon={<IconTrash size={14} />}
                  disabled={
                    roleProvided === 'user' || roleProvided === 'brand manager'
                  }
                >
                  Delete 
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ),
        },
        {
          accessor: 'brand[0].name',
          Header: 'Brand',
          textAlignment: 'center',
          minWidth: 40,
        },
        {
          accessor: 'description',
          Header: 'Description',
          textAlignment: 'center',
          minWidth: 40,
          Cell: ({ value }: { value: string }) => (
            <div dangerouslySetInnerHTML={{ __html: value }} style={{
              display:"flex",
              maxWidth: 160,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }} />
          )
        },
        {
          accessor: 'title',
          Header: 'title',
          textAlignment: 'left',
          minWidth: 350,
        },
  
        {
          accessor: 'Image[0].file].Location',
          Header: 'Image',
          textAlignment: 'center',
          minWidth: 80,
          Cell: ({ value }: any) => (
            <div>
              {value !== null ? (
                <img
                  src={value}
                  height={70}
                  width={80}
                  alt="NO IMAGES FOUND"
                  style={{ margin: '10px' }}
                />
              ) : (
                <img
                  src={imagenotfound}
                  height={70}
                  width={80}
                  alt="NO IMAGES FOUND"
                  style={{ margin: '10px' }}
                />
              )}
            </div>
          ),
        },
  
        {
          accessor: 'status',
          Header: 'Status',
          width: 90,
          textAlignment: 'center',
          Cell: ({ ...status }) => {
            if (status.row.original.status === true) {
              return <IconCircleCheck color="green" size={30} />
            }
            return <IconCircleX color="#ff2825" size={30} />
          },
        },
      ],
      [roleProvided, setSearchParams,handleDeleteBlogs]
    )
  
    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
      (
        {
          label,
          description,
          cityName,
          logo,
  
          ...others
        }: ItemProps,
        ref
      ) => (
        <div ref={ref} {...others}>
          <Group noWrap>
            <div>
              <Grid>
                {logo ? (
                  <Grid.Col span={10}>
                    <Text size="sm" sx={{ textTransform: 'capitalize' }}>
                      {label}
                    </Text>
                  </Grid.Col>
                ) : (
                  <>
                    <Grid.Col span={1} sx={{ marginRight: '4px' }}>
                      <ThemeIcon
                        size="sm"
                        variant="light"
                        gradient={{ from: 'indigo', to: 'cyan' }}
                      >
                        <IconBuildingSkyscraper />
                      </ThemeIcon>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <Text size="sm" sx={{ textTransform: 'capitalize' }}>
                        {label}
                      </Text>
                      <Text
                        size="xs"
                        opacity={0.65}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {description}-{cityName}
                      </Text>
                    </Grid.Col>
                  </>
                )}
              </Grid>
            </div>
          </Group>
        </div>
      )
    )
    SelectItem.displayName = 'SelectItem'
    useEffect(() => {
      if (filterRef.current != null) {
        setTableHeight(window.innerHeight - filterRef.current.clientHeight - 125)
      }
  
      if (totalRowSize > pageSize) {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        setCountForm(from + 1)
        setCountTo(to)
      } else {
        setCountForm(1)
        setCountTo(totalRowSize)
      }
      let rowDataSize
  
      if (totalRowSize >= pageSize) {
        rowDataSize = totalRowSize / pageSize
  
        if (rowDataSize - Math.floor(rowDataSize) !== 0) {
          setPageNo(Math.ceil(rowDataSize))
        } else {
          setPageNo(rowDataSize)
        }
      } else {
        rowDataSize = Math.ceil(totalRowSize / pageSize)
        setPageNo(rowDataSize)
      }
    }, [page, pageSize, recordData, theme, totalRowSize])
  console.log(recordData,"recordData")
    return (
      <>
        <Box>
          <Paper shadow="xs" p="md">
            <Grid ref={filterRef}>
              <Grid.Col xs={12} sm={4} md={4} lg={7} xl={7}>
                <Title order={3} weight={100}>
                  Blogs
                </Title>
              </Grid.Col>
              {roleProvided === 'super admin' ? (
                <>
                  <Grid.Col xs={12} sm={2} md={2} lg={1.5} xl={1.5}>
                    <Popover width={340} position="bottom" withArrow shadow="xl">
                      <Popover.Target>
                        <Button
                          // className={classes.filter}
                          style={{ width: '100%' }}
                          gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                          disabled={loading}
                        >
                          <IconFilter />
                          <span style={{ marginLeft: '10px' }}>Filter</span>
                        </Button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        {roleProvided === 'super admin' ? (
                          <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Select
                              label="Select Brand"
                              placeholder="Select Brand"
                              itemComponent={SelectItem}
                              data={brand}
                              limit={brand?.length}
                              disabled={brand?.length === 0}
                              searchable
                              maxDropdownHeight={200}
                              value={brandValue}
                              nothingFound="No Data Found"
                              clearable
                              onChange={(value: any) => {
                                setLoading(true)
                                setPage(1)
                                setPageNo(1)
                                setBrandValue(value)
                              }}
                              styles={() => ({
                                item: {
                                  // applies styles to selected item
                                  '&[data-selected]': {
                                    '&, &:hover': {
                                      backgroundColor:
                                        theme.colorScheme === 'dark'
                                          ? theme.colors.teal[9]
                                          : '#e1f5d0',
                                      color:
                                        theme.colorScheme === 'dark'
                                          ? theme.white
                                          : theme.black,
                                    },
                                  },
  
                                  // applies styles to hovered item (with mouse or keyboard)
                                  '&[data-hovered]': {},
                                },
                              })}
                            />
                          </Grid.Col>
                        ) : null}
                      </Popover.Dropdown>
                    </Popover>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                      variant="gradient"
                      // className={classes.import}
                      style={{ width: '100%' }}
                      gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      leftIcon={<IconUpload size={18} />}
                      onClick={() => {
                        setModel(true)
                        setSearchParams({ mode: 'add' })
                      }}
                    >
                      Add Article 
                    </Button>
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col xs={12} sm={4.5} md={4.5} lg={3.5} xl={3.5} />
                  <Grid.Col
                    xs={12}
                    sm={2}
                    md={2}
                    lg={1.5}
                    xl={1.5}
                    sx={{ display: 'flex', justifyContent: 'right' }}
                  >
                    {roleProvided !== 'user' ? (
                      <Popover
                        width={350}
                        position="bottom"
                        withArrow
                        shadow="xl"
                      >
                        <Popover.Target>
                          <Button
                            // className={classes.filter}
                            style={{ width: '100%' }}
                            gradient={{ from: 'teal', to: 'lime', deg: 150 }}
                            disabled={loading}
                          >
                            <IconFilter />
                            <span style={{ marginLeft: '10px' }}>Filter</span>
                          </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          {roleProvided === 'CRM manager' ? (
                            <Grid.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Select
                                label="Select Brand"
                                placeholder="Select Brand"
                                itemComponent={SelectItem}
                                data={brand}
                                limit={brand?.length}
                                disabled={brand?.length === 0}
                                searchable
                                maxDropdownHeight={200}
                                value={brandValue}
                                nothingFound="No Data Found"
                                clearable
                                onChange={(value: any) => {
                                  setLoading(true)
                                  setPage(1)
                                  setPageNo(1)
                                  setBrandValue(value)
                                  setStoreValue(null)
                                }}
                                styles={() => ({
                                  item: {
                                    // applies styles to selected item
                                    '&[data-selected]': {
                                      '&, &:hover': {
                                        backgroundColor:
                                          theme.colorScheme === 'dark'
                                            ? theme.colors.teal[9]
                                            : '#e1f5d0',
                                        color:
                                          theme.colorScheme === 'dark'
                                            ? theme.white
                                            : theme.black,
                                      },
                                    },
  
                                    // applies styles to hovered item (with mouse or keyboard)
                                    '&[data-hovered]': {},
                                  },
                                })}
                              />
                            </Grid.Col>
                          ) : null}
                        </Popover.Dropdown>
                      </Popover>
                    ) : (
                      ''
                    )}
                  </Grid.Col>
                </>
              )}{' '}
            </Grid>
            <Grid>
              <Grid.Col xs={12} style={{ marginTop: '20px', width: 1060 }}>
                <CustomTable
                  pageFrom={countFrom}
                  pageTo={countTo}
                  total={pageNo}
                  gridApi={(instance) => {}}
                  totalRecords={!loading ? totalRowSize : 1}
                  checkboxSelection={false}
                  recordsPerPage={pageSize}
                  page={page}
                  height={tableHeight}
                  onPageChange={(p: any) => setPage(p)}
                  recordsPerPageOptions={PAGESIZES}
                  onRecordsPerPageChange={setPageSize}
                  loading={!!loading}
                  rowData={!loading ? recordData : []}
                  colDef={colDef}
                />
              </Grid.Col>
            </Grid>
          </Paper>
        </Box>
        <Modal
          opened={open}
          centered
          onClose={() => {
            setOpen(false)
            setSearchParams({})
          }}
          title="View Articles"
          size="lg"
        >
          <ArticleViewForm onClose={handleClose} />
        </Modal>
  
        <Modal
          opened={modelState}
          centered
          onClose={() => {
            setModel(false)
            setSearchParams({})
          }}
          style={{
            fontSize: '18px',
            textTransform: 'capitalize',
            fontWeight: 500,
          }}
          title={`${mode} Articles`}
          size="xl"
        >
          <ArticleUpload
            fetchData={() => fetchData()}
            onClose={() => {
              setSearchParams('')
              handleClose()
            }}
            mode={searchParams.get('mode')}
            isEditMode={searchParams.get('mode')?.toLowerCase() === 'edit'}
            isViewMode={searchParams.get('mode')?.toLowerCase() === 'view'}
            productId={searchParams.get('id')}
          />
        </Modal>
        <Modal
          opened={deleteOpen}
          centered
          onClose={() => {
            setDeleteOpen(false)
            setSearchParams({})
          }}
          title="Delete Product"
          size="lg"
        >
          <DeleteModal
            deleteName={deleteName}
            fetchData={fetchData}
            onClose={handleClose}
            deleteApi={deleteProduct}
            deletedGetApi={deletedData}
          />
        </Modal>
      </>
    )
  }
  