import { IconUsers, IconChartBar } from '@tabler/icons'
import { LinksGroupProps } from '../components/NavBarLinkGroups'

export const ROUTE_PATH = {
  home: '/',
  login: '/login',
  brand: '/superadmin/brand',
  type: '/superadmin/types',
  category: '/superadmin/category',
  user: '/superadmin/user',
  token: '/superadmin/token',
  store: '/superadmin/stores',
  product: '/superadmin/products',
  state: '/superadmin/state',
  city: '/superadmin/city',
  subCategory: '/superadmin/subCategory',
  knowlarity: '/superadmin/virtualphonenumber',
  calllog: '/superadmin/calllog',
  keywords: '/superadmin/keywords',
  calllogChart: '/superadmin/calllogChart',
  action: '/superadmin/action',
  article: '/superadmin/article',
  callActions: '/superadmin/callActions',
  discovery: '/superadmin/discovery',
  gmbDelete: '/superadmin/gmbDelete',
  keywordsDelete: '/superadmin/keywordsdelete',
  monthlyReport: '/superadmin/monthlyReport',
  fileUpload: '/superadmin/textFile',
  pagesonfly: '/superadmin/pagesonfly',
  URLIndex: '/superadmin/urlindexing',
  keywordList: '/superadmin/keywordList',
  seotool: '/superadmin/seotool',
  blogArtilces: '/superadmin/blogs',
}

export const drawerOptions: LinksGroupProps[] = [
  {
    label: 'Admin',
    icon: IconUsers,
    initiallyOpened: true,
    links: [
      { label: 'Brand', link: ROUTE_PATH.brand },
      { label: 'Store', link: ROUTE_PATH.store },
      { label: 'Product', link: ROUTE_PATH.product },
      { label: 'Type', link: ROUTE_PATH.type },
      { label: 'Category', link: ROUTE_PATH.category },
      { label: 'SubCategory', link: ROUTE_PATH.subCategory },
      { label: 'State', link: ROUTE_PATH.state },
      { label: 'City', link: ROUTE_PATH.city },
      { label: 'Users', link: ROUTE_PATH.user },
      { label: 'Virtual Number Mapping ', link: ROUTE_PATH.knowlarity },
      { label: 'Indexing', link: ROUTE_PATH.fileUpload },
      { label: 'Article', link: ROUTE_PATH.article },
      { label: 'Blog', link: ROUTE_PATH.blogArtilces },
      { label: 'URL Rank Checker', link: ROUTE_PATH.seotool },
    ],
  },
  {
    label: 'Dashboard',
    icon: IconChartBar,
    initiallyOpened: false,
    links: [
      { label: 'CallLog', link: ROUTE_PATH.calllog },
      { label: 'Discovery', link: ROUTE_PATH.discovery },
      { label: 'Action', link: ROUTE_PATH.callActions },
      { label: 'Keywords', link: ROUTE_PATH.keywords },
      { label: 'Monthly Report', link: ROUTE_PATH.monthlyReport },
    ],
  },
]
