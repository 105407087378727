import { IconCheck, IconX, IconAlertTriangle } from '@tabler/icons'
import { getLocalStorageItem } from '../utils/localStorage'

export const ROLEPROVIDED = getLocalStorageItem('role')
export const BRANDID = JSON.parse(getLocalStorageItem('brandId') as any)
export const STOREID = JSON.parse(getLocalStorageItem('storeId') as any)
export const STORECODE = JSON.parse(getLocalStorageItem('storeCode') as any)
export const SATHYAID = '63e387a7f60befaca421836e'

export const ROUTE_PATH = {
  home: '/',
  login: '/login',
  brand: '/brand',
  type: '/types',
  category: '/category',
  token: '/token',
  store: '/stores',
  product: '/products',
  state: '/state',
  city: '/city',
  subCategory: '/subCategory',
  user: '/user',
  knowlarity: '/virtualphonenumber',
  calllog: '/calllog',
  keywords: '/keywords',
  calllogChart: '/calllogChart',
  articles: '/article',
  action: '/action',
  callActions: '/callActions',
  discovery: '/discovery',
  gmbDelete: '/gmbDelete',
  keywordsDelete: '/keywordsdelete',
  monthlyReport: '/monthlyReport',
  fileUpload: '/textFile',
  pagesonfly: '/pagesonfly',
  URLIndex: '/urlindexing',
  keywordList: '/keywordList',
  seotool: '/seotool',
  blogArticles: '/blogs',
}
export const API_ENDPOINTS = {
  LOGIN: '/api/user/login',
  POST_STORE: '/api/store/add',
  GET_STORE: '/api/store/get',
  EDIT_STORE: '/api/store',
  DELETE_STORE: '/api/store',
  DELETE_FILE: '/api/store/deleteFile',
  STORE_GALLERY: '/api/store/addGallery',
  BRAND_GALLERY: '/api/brand/addGallery',
  STORE_UPLOAD: '/api/store/upload',
  POST_BRAND: '/api/brand/create',
  GET_BRAND: '/api/brand/get',
  EDIT_BRAND: '/api/brand/edit',
  DELETE_BRAND: '/api/brand/delete',
  GET_PRODUCT: '/api/product/get',
  POST_PRODUCT: '/api/product/add',
  POST_UPLOAD: '/api/product/upload',
  DELETE_PRODUCT: '/api/product',
  PRODUCT_GALLERY: '/api/product/addGallery',
  PRODUCT_DELETE_GALLERY: '/api/product/deleteFile',
  STORE_DELETE_GALLERY: '/api/store/deleteFile',
  BRAND_DELETE_GALLERY: '/api/brand/deleteFile',
  EDIT_PRODUCT: '/api/product',
  GET_STATE: '/api/register/state',
  POST_STATE: '/api/register/state',
  DELETE_STATE: '/api/register/state',
  PATCH_STATE: '/api/register/state',
  POST_TYPE: '/api/register/types',
  EDIT_TYPE: '/api/register/types',
  DELETE_TYPE: '/api/register/types',
  GET_TYPE: '/api/register/types',
  CITY_DETAILS: '/api/register/city',
  STATE_DETAILS: '/api/register/state',
  GET_STATE_DETAILS: '/api/store/get',
  GET_CITY_DETAILS: '/api/store/get',
  CATEGORY_DETAILS: '/api/register/category',
  POST_USER: '/api/user/create',
  GET_USER: '/api/user/get',
  EDIT_USER: '/api/user/edit',
  DELETE_USER: '/api/user/delete',
  GET_CALLMAP: '/api/callmap/get',
  POST_CALLMAP: '/api/callmap/add',
  PATCH_CALLMAP: '/api/callmap',
  DELETE_CALLMAP: '/api/callmap',
  GET_EXOTEL_CALLLOG: '/api/calls/',
  EXPORT_CALLLOG: '/api/callLog/export',
  EXPORT_EXOTEL_CALLLOG: '/api/calls/export',
  MONTH_CALL_WISE: '/api/calls/monthWiseStatus',
  GET_OVERALL_CALLSTATUS: '/api/calls/overAllStatus',
  GET_ACTION_TABLE: '/api/gmb/insight/get',
  GET_ANALYTICS_GRAPH: '/api/gmb/insight/analytics',
  GET_TOTAL_VIEWS: '/api/gmb/insight/analytics',
  UPLOAD_GMB_INSIGHTS: '/api/gmb/insight/upload',
  INDEXING_FILE_UPLOAD: '/api/index',
  EXPORT_GMB_INSIGHTS: '/api/gmb/insight/download',
  GET_AVAILABLE_DATES: '/api/gmb/insight/availableDates',
  GET_SEARCH_AVAILABLE_DATES: '/api/searchlogs/availDates',
  UPLOAD_KEYWORDS: '/api/searchlogs/upload',
  GET_KEYWORDS: '/api/searchlogs',
  DELETE_KEYWORDS: '/api/searchlogs',
  DELETE_GMB_INSIGHTS: '/api/gmb/insight/',
  GET_REPORTS: '/api/reports',
  GET_REPORT_AVAILABLE_DATES: '/api/reports/getAvailableDates',
  GET_SERVICE_ACCOUNT: '/api/service-account',
  GENERATE_SERVICE_ACCOUNT: '/api/service-account/index',
  CACHE_FLUSH: '/cache/flush',
  GET_ARTICLES: '/api/articleslinking/getArticlesData',
  POST_ARTICLES: '/api/articleslinking/postArticles',
  EDIT_ARTICLES: '/api/articleslinking/updateArticles',
  DELETE_ARTICLES: '/api/articleslinking/deleteArticles',
  GET_ARTICLEPRODUCT: '/api/articleslinking/getarticlesCategorys',
  DELETE_ARTICLEIMAGE: '/api/articleslinking/delets3Image',
  UPLOAD_SELECTED_KEYWORD: '/api/keywords/postKeywords',
  GET_SELECTED_KEYWORD: '/api/keywords/getKeywords',
  DELETE_SELECTED_KEYWORD: '/api/keywords/deleteKeywords',
  GET_DYNAMIC_CONTENT: '/api/dynamicContents/getuniqueContents',
  UPDATE_DYNAMIC_CONTENT: '/api/dynamicContents/updatecontents',
  DELETE_DYNAMIC_CONTENT: '/api/dynamicContents/deletecontents',
  GET_DYNAMIC_CONTENT_PRODUCT: '/api/dynamicContents/getbrandwiseproductsdata',
  EXPORT_DYNAMIC_CONTENT: '/api/dynamicContents/exportfile',
  GET_URL_INDEXING: '/api/index/getindexingurls',
  EXPORT_URL_INDEXING: '/api/index/exporturls',
  GET_VIEW_KEYWORDDETAILS: '/api/dynamicContents/getdynamicpagecontentcounts',
  SEO_RANKING_CHECKER_TOOL: '/api/ranking/getResponce',
  POST_BLOGS: '/api/blogs/createblog',
  GET_BLOGS: '/api/blogs/getblogs',
  DELETE_BLOG: '/api/blogs/deleteblog',
  UPDATE_BLOG: '/api/blogs/updateblog',
  FIND_ONE_BLOG: '/api/blogs/findoneblog',
}
export const AlertIcons = {
  success: IconCheck,
  warn: IconAlertTriangle,
  error: IconX,
}

export const AlertColor = {
  success: 'green',
  warn: 'yellow',
  error: 'red',
}
export const PAGESIZES = [10, 20, 30, 50, 100]

export const ROLE = {
  ADMIN: 'admin',
  USER: 'user',
  SUPERADMIN: 'super admin',
  BRANDMANAGER: 'brand manager',
  CRMMANAGER: 'CRM manager',
  ADMINMANAGER: 'admin manager',
}

export const PERMISSION = {
  [ROLE.ADMIN]: ['view', 'update', 'delete', 'edit'],
  [ROLE.CRMMANAGER]: ['view', 'update', 'delete', 'edit'],
  [ROLE.SUPERADMIN]: ['view', 'update', 'delete', 'edit'],
  [ROLE.USER]: ['view'],
  [ROLE.BRANDMANAGER]: ['view'],
}
